import { IconSpinner, LayoutSingleColumn, MobileBottomNavigation, Page } from '../../components';
import FooterContainer from '../../containers/FooterContainer/FooterContainer';
import { formatMoney } from '../../util/currency';
import { FormattedMessage } from '../../util/reactIntl';
import css from './ListingPage.module.css';

/**
 * Helper to get formattedPrice and priceTitle for SectionHeading component.
 * @param {Money} price listing's price
 * @param {String} marketplaceCurrency currency of the price (e.g. 'USD')
 * @param {Object} intl React Intl instance
 * @returns Object literal containing formattedPrice and priceTitle
 */
// TODO: marketplaceCurrency is not used
export const priceData = (price, marketplaceCurrency, intl) => {
  if (price && price.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  }
  return {};
};

/**
 * Create fallback views for the ListingPage: LoadingPage and ErrorPage.
 * The PlainPage is just a helper for them.
 */
const PlainPage = props => {
  const { title, topbar, scrollingDisabled, children } = props;
  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn topbar={topbar} footer={<MobileBottomNavigation />}>
        {children}
        <FooterContainer />
      </LayoutSingleColumn>
    </Page>
  );
};

export const ErrorPage = props => {
  const { topbar, scrollingDisabled, intl } = props;
  return (
    <PlainPage
      title={intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      })}
      topbar={topbar}
      scrollingDisabled={scrollingDisabled}
    >
      <p className={css.errorText}>
        <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
      </p>
    </PlainPage>
  );
};

export const LoadingPage = props => {
  const { topbar, scrollingDisabled, intl } = props;
  return (
    <PlainPage
      title={intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      })}
      topbar={topbar}
      scrollingDisabled={scrollingDisabled}
    >
      <p className="h-[calc(100vh-var(--topbarHeight)-var(--mobileBottomNavHeight))] grid place-items-center w-screen">
        <IconSpinner />
      </p>
    </PlainPage>
  );
};
