/* Stripe related configuration.

NOTE: REACT_APP_STRIPE_PUBLISHABLE_KEY is mandatory environment variable.
This variable is set in a hidden file: .env
To make Stripe connection work, you also need to set Stripe's private key in the Flex Console.
*/

import { loadStripe } from '@stripe/stripe-js';

import { STRIPE_PUBLISHABLE_KEY } from './env';

export const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
