import { Elements, ExpressCheckoutElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeExpressCheckoutElementConfirmEvent } from '@stripe/stripe-js';
import React, { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';

import { useSaveAddressIfNonExistent } from './ExpressCheckout.hooks';
import IconSpinner from 'components/IconSpinner/IconSpinner';
import { stripePromise } from 'config/configStripe';
import {
  getShippingRatesQueryFn,
  useConfirmPayment,
  useRedirectToOrdersPage,
} from 'containers/CheckoutPage/CheckoutPage.hooks';
import { getLineItems, useGetLineItems } from 'hooks/api/transactions/useGetLineItems';
import { useInitiateOrder } from 'hooks/api/transactions/useInitiateOrder';
import { useGeolocation } from 'hooks/useGeolocation';
import { EuCountryCode } from 'translations/countryCodes';
import { post } from 'util/httpsClient';
import { logger } from 'util/log';
import { getPath } from 'util/routes';

type P = {
  listingId: string;
  listing: any;
};

const ExpressCheckout: React.FC<P> = ({ listingId, listing }) => {
  const history = useHistory();
  const [useNoldCredit, setUseNoldCredit] = useState(false);
  const [initialAmountAndCurrency, setInitialAmountAndCurrency] = useState<{
    amount: number;
    currency: string;
  }>();
  const { userGeolocation } = useGeolocation();

  const [orderData, setOrderData] = useState({
    shippingAddressId: '',
    selectedRateId: '',
    billingAddressId: null,
    discountCodes: [] as string[],
    useNoldCredit: false,
    noldCreditAmount: 0,
    deliveryMethod: '',
    authenticate: false,
  });

  const {
    data: lineItems,
    error: lineItemsError,
    isLoading: isLoadingLineItems,
  } = useGetLineItems({
    listingId,
    rateId: orderData.selectedRateId,
    userGeolocation: userGeolocation || 'GB',
    discountCodes: orderData.discountCodes,
  });

  if (lineItemsError) {
    if (lineItemsError.data?.errors[0]?.type === 'DiscountCodeError') {
      console.error('Failed to apply discount code:', lineItemsError);
      const errorMessage =
        lineItemsError.data?.errors?.[0]?.displayMessage ||
        'Failed to apply discount code. Please try again.';

      setOrderData(prev => ({
        ...prev,
        discountCodes: prev.discountCodes.slice(0, -1),
      }));
    } else if (lineItemsError.data?.errors[0]?.type === 'OutOfStockError') {
      console.error('Out of stock error:', lineItemsError);
      toast.error('Sorry, this item is no longer available', {
        duration: 10000,
      });

      const searchPagePath = getPath('SearchPage');
      history.push(`${searchPagePath}`);
    }
  }

  useEffect(() => {
    setOrderData(prevData => ({
      ...prevData,
      useNoldCredit,
    }));
  }, [useNoldCredit]);

  useEffect(() => {
    if (!lineItems || initialAmountAndCurrency) {
      return;
    }

    let amount, currency;
    if (lineItems) {
      amount = lineItems.payinTotal.amount;
      currency = lineItems.payinTotal.currency.toLowerCase();
    }

    if (amount && currency) {
      setInitialAmountAndCurrency({ amount, currency });
    }
  }, [initialAmountAndCurrency, lineItems]);

  if (!initialAmountAndCurrency || !listing) {
    return (
      <div className="h-[44px] bg-black rounded-full grid place-items-center text-white">
        <IconSpinner />
      </div>
    );
  }

  return (
    <div className="relative">
      {
        <div className="absolute inset-[0px] h-[44px] bg-black rounded-full grid place-items-center text-white">
          <IconSpinner />
        </div>
      }
      <Elements
        stripe={stripePromise}
        options={{
          mode: 'payment',
          amount: initialAmountAndCurrency!.amount,
          currency: initialAmountAndCurrency!.currency,
          capture_method: 'manual',
          appearance: {
            variables: {
              borderRadius: '50%',
            },
          },
        }}
      >
        <ExpressCheckoutHelper
          listingId={listingId}
          listing={listing}
          orderData={orderData}
          setOrderData={setOrderData}
          isLoadingLineItems={isLoadingLineItems}
        />
      </Elements>
      {/* </div>
      </Modal> */}
    </div>
  );
};

const ExpressCheckoutHelper: React.FC<{
  listingId: string;
  listing: any;
  orderData: any;
  setOrderData: React.Dispatch<React.SetStateAction<any>>;
  isLoadingLineItems: boolean;
}> = ({ listingId, listing, orderData, setOrderData, isLoadingLineItems }) => {
  const stripe = useStripe();
  const elements = useElements();
  const shippingToCountryRef = React.useRef('');
  const { mutateAsync: initiateOrder } = useInitiateOrder();
  const { mutateAsync: confirmPayment } = useConfirmPayment();
  const saveAddressIfNonExistent = useSaveAddressIfNonExistent();
  const redirectToOrdersPage = useRedirectToOrdersPage();
  const { userGeolocation } = useGeolocation();

  const getUpdatedLineItems = useCallback(
    async (rateId, country) => {
      try {
        const updatedLineItems = await getLineItems({
          listingId,
          rateId,
          userGeolocation: userGeolocation || 'GB',
          shippingToCountry: country,
          discountCodes: orderData.discountCodes,
        });
        const total = updatedLineItems.payinTotal;
        const lineItems = updatedLineItems.lineItems;

        const stripeLineItems = lineItems
          .filter(item => item.includeFor?.includes('customer'))
          .map(item => ({
            name: item.code
              .replace('line-item/', '')
              .replaceAll('-', ' ')
              .replace(/\b(\w)/g, function (firstLetter) {
                return firstLetter.toUpperCase();
              }),
            amount: item.unitPrice.amount,
          }));

        return { total, stripeLineItems };
      } catch (error) {
        console.error('Failed to update line items', error);
        throw error;
      }
    },
    [listingId, orderData.discountCodes, userGeolocation]
  );

  if (!elements || !stripe) {
    return null;
  }

  const shipsTo = JSON.stringify(listing?.attributes?.publicData.shipsTo) || '';
  let allowedShippingCountries;
  switch (shipsTo) {
    case '["eu"]':
      allowedShippingCountries = [...Object.values(EuCountryCode)];
      break;
    case '["gb"]':
      allowedShippingCountries = ['GB'];
      break;
    case '["eu","gb"]':
      allowedShippingCountries = ['GB', ...Object.values(EuCountryCode)];
      break;
  }

  return (
    <ExpressCheckoutElement
      options={{
        paymentMethods: {
          applePay: 'always',
          amazonPay: 'never',
          paypal: 'never',
          link: 'never',
          googlePay: 'never',
        },
      }}
      onClick={async handler => {
        if (isLoadingLineItems) {
          return;
        }

        handler.resolve({
          emailRequired: true,
          lineItems: [],
          shippingAddressRequired: true,
          billingAddressRequired: true,
          phoneNumberRequired: true,
          allowedShippingCountries: allowedShippingCountries,
          shippingRates: [
            {
              id: 'none',
              displayName: 'Enter an address',
              amount: 0,
            },
          ],
        });
      }}
      onShippingRateChange={async event => {
        try {
          if (isLoadingLineItems) {
            return;
          }
          setOrderData(prev => ({
            ...prev,
            selectedRateId: event.shippingRate.id,
            shippingToCountry: shippingToCountryRef.current,
          }));
          const { total, stripeLineItems } = await getUpdatedLineItems(
            event.shippingRate.id,
            shippingToCountryRef.current
          );

          elements?.update({
            amount: total.amount,
            currency: total.currency.toLowerCase(),
          });
          event.resolve({
            lineItems: stripeLineItems,
          });
        } catch (error: any) {
          console.error('Failed to update shipping rate', error);
          event.reject();
        }
      }}
      onShippingAddressChange={async event => {
        const res = await getShippingRatesQueryFn({
          listingId,
          userGeolocation,
          address: {
            zip: event.address.postal_code,
            city: event.address.city,
            country: event.address.country,
          },
        })();

        const rates = res.map(rate => ({
          id: rate.id,
          displayName: rate.name,
          amount: Math.round(parseFloat(rate.amount) * 100),
        }));
        if (rates.length === 0) {
          return event.reject();
        }

        shippingToCountryRef.current = event.address.country;
        event.resolve({
          shippingRates: rates,
        });
      }}
      onConfirm={async function (event: StripeExpressCheckoutElementConfirmEvent) {
        const [shippingAddress, billingAddress] = await Promise.all([
          saveAddressIfNonExistent({
            address: event.shippingAddress!.address,
            name: event.shippingAddress!.name || event.billingDetails?.name || '',
            phone: event.billingDetails?.phone || '',
          }),
          saveAddressIfNonExistent({
            address: event.billingDetails!.address,
            name: event.billingDetails?.name || event.shippingAddress?.name || '',
            phone: event.billingDetails?.phone || '',
          }),
        ]);
        if (!shippingAddress || !billingAddress) {
          logger.error('Failed to save shipping or billing address');
          return event.paymentFailed();
        }

        const rates = await getShippingRatesQueryFn({
          listingId,
          buyerAddressId: shippingAddress.id,
          userGeolocation,
        })();
        const fullRate = rates.find(
          rate =>
            rate.name === event.shippingRate!.displayName &&
            Math.round(parseFloat(rate.amount) * 100) === event.shippingRate!.amount
        );

        const response = await initiateOrder({
          listingId,
          shippingToAddressId: Number(shippingAddress.id),
          billingAddressId: Number(billingAddress.id),
          rateId: fullRate.id,
          temporaryRateId: event.shippingRate!.id,
          useNoldCredit: orderData.useNoldCredit,
          authenticate: orderData.authenticate,
          discountCodes: orderData.discountCodes,
          isExpressCheckout: true,
          userGeolocation: userGeolocation || 'GB',
        });

        const { error: submitError } = await elements.submit();
        if (submitError) {
          logger.exception(submitError);
          return event.paymentFailed();
        }

        const { error, confirmationToken } = await stripe.createConfirmationToken({ elements });
        if (error) {
          toast.error('Invalid payment details. Please try again.');
          logger.exception(error);
          return event.paymentFailed();
        }

        const paymentIntent = await post({
          path: '/payment-intents',
          body: {
            transactionId: response.id.uuid || response.id,
            listingId,
            confirmationToken: confirmationToken.id,
          },
        });

        await confirmPayment({
          transactionId: response.id.uuid || response.id,
          listingId,
          paymentIntentId: paymentIntent.id,
        });

        redirectToOrdersPage();
      }}
    />
  );
};

export default ExpressCheckout;
